/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';

import EnhancedTableToolbar from './EventListEnhancedTableToolbar';

// table header options
const headCells = [
    {
        id: 'id',
        numeric: true,
        label: '',
        align: 'center'
    },
    {
        id: 'name',
        numeric: false,
        label: 'Product Name',
        align: 'left'
    },
    {
        id: 'type',
        numeric: false,
        label: 'Event Type',
        align: 'left'
    },
    {
        id: 'start_date',
        numeric: false,
        label: 'Start Date',
        align: 'left'
    },
    {
        id: 'entry_fee',
        numeric: true,
        label: 'Price',
        align: 'right'
    },
    {
        id: 'orders_sum_amount_transferred',
        numeric: true,
        label: 'Revenue',
        align: 'right'
    },
    {
        id: 'totalTeams',
        numeric: true,
        label: 'Teams',
        align: 'right'
    },

    {
        id: 'status',
        numeric: true,
        label: 'Status',
        align: 'center'
    }
];
function EnhancedTableHead({ order, orderBy, numSelected, onRequestSort, theme, selected }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead data-test-id="views/home/Home/EventList">
            <TableRow>
                {numSelected > 0 && (
                    <TableCell padding="none" colSpan={7}>
                        <EnhancedTableToolbar numSelected={selected?.length} />
                    </TableCell>
                )}
                {numSelected <= 0 &&
                    headCells?.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Typography component="span" sx={{ display: 'none' }}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Typography>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                {numSelected <= 0 && (
                    <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        <Typography
                            variant="subtitle1"
                            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'grey.900' }}
                        >
                            Action
                        </Typography>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    theme: PropTypes.object,
    selected: PropTypes.array,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

export default EnhancedTableHead;
