/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable no-throw-literal */
import React, { useState, createContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import actionSnackbar from 'ui-component/actionSnackbar';

export const LeaguesContext = createContext();
function getNextHour(hours) {
    const now = new Date();
    now.setHours(now.getHours() + hours);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
}
const initialState = {
    title: undefined,
    description: undefined,
    startDate: getNextHour(1),
    endDate: getNextHour(2),
    location: undefined,
    repeat: 'Weekly',
    interval: 1,
    until: undefined,
    loading: { playingGroupPoll: true, eventList: false, leagueRoster: false, eventTeams: false, matches: true },
    reminder: 'None',
    leagueStartDate: undefined,
    leagueEndDate: undefined,
    allDay: false,
    repeatEndDate: undefined,
    duration: 60,
    dialogVisible: false,
    attendance: true,
    minimumRatingToggle: false,
    minimumRating: undefined,
    price: undefined,
    participantLimit: undefined,
    participantLimitToggle: false,
    priceToggle: false,
    sendInvites: 0,
    reportToDupr: false,
    registrationDeadline: undefined,
    courtsAvailable: undefined,
    eventList: [],
    attendeeList: [],
    eventWaitlist: [],
    playingGroupPoll: {},
    userPlayingGroup: {},
    eventTypes: [],
    isUploadingToDupr: false,
    eventChecklist: {},
    leagueEventList: [],
    eventTeams: [],
    leagueCourts: [],
    tournamentTeams: [],
    hasLeaderboard: false,
    userMatches: [],
    poolRounds: [],
    selectedEvent: undefined,
    eventBracket: undefined,
    week: 0,
    selectedDivisionId: undefined,
    options: [],
    divisionTeamResults: { team_a: [], team_b: [] },
    poolType: undefined
};
const updateNestedStateKey = (keyPath, value) => ({
    type: 'UPDATE_NESTED_STATE_KEY',
    payload: { keyPath, value }
});
function updateNestedStateKeyReducer(state, keyPath, value) {
    const keys = keyPath.split('.');
    const nestedState = { ...state };

    let currentLevel = nestedState;
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        currentLevel[key] = { ...currentLevel[key] };
        currentLevel = currentLevel[key];
    }

    currentLevel[keys[keys.length - 1]] = value;

    return nestedState;
}

function leaguesReducer(state, action) {
    switch (action.type) {
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        case 'SET_ARRAY_STATE':
            return { ...state, [action.payload.key]: [...state[action.payload.key], action.payload.value] };
        case 'RESET_STATE':
            return initialState; // Resetting to initial state
        case 'REMOVE_EVENT_BY_ID':
            // Filter out the event with the matching ID
            const updatedEventList = state.eventList.filter((event) => event.event.id !== action.payload);
            return { ...state, eventList: updatedEventList };
        case 'UPDATE_ATTENDEE_STATUS':
            console.log('Payload for updating attendee status:', action.payload);
            const updatedAttendeeList = state.attendeeList.map((event) => {
                if (event.invitation_id === action.payload.id) {
                    return { ...event, status: action.payload.status };
                }
                return event;
            });
            console.log('Updated attendee list:', updatedAttendeeList);
            return { ...state, attendeeList: updatedAttendeeList };
        case 'REPLACE_ARRAY_ITEM': {
            const { key, id, newItem } = action.payload;
            const newArray = state[key].map((item) => (item.id === id ? newItem : item));
            return { ...state, [key]: newArray };
        }
        case 'SET_LOADING_STATE':
            return {
                ...state,
                loading: { ...state.loading, [action.payload.key]: true }
            };
        case 'CLEAR_LOADING_STATE':
            return {
                ...state,
                loading: { ...state.loading, [action.payload.key]: false }
            };
        case 'UPDATE_NESTED_STATE_KEY':
            return updateNestedStateKeyReducer(state, action.payload.keyPath, action.payload.value);
        default:
            return state;
    }
}

export function LeaguesProvider({ children }) {
    const [state, dispatch] = useReducer(leaguesReducer, initialState);
    const [leagues, setLeagues] = useState([]);
    const [clubLeagues, setClubLeagues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [eventMatchups, setEventMatchups] = useState([]);
    const [errors, setErrors] = useState({});
    const [selectedDays, setSelectedDays] = useState([]);
    const [eventPools, setEventPools] = useState([]);
    const [eventModalOpen, setEventModalOpen] = useState(false);

    const handleToggleModal = () => {
        setEventModalOpen(!eventModalOpen);
    };

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };
    const updateValue = (keyPath, value) => {
        dispatch(updateNestedStateKey(keyPath, value));
    };
    const setArray = (key, value) => {
        dispatch({ type: 'SET_ARRAY_STATE', payload: { key, value } });
    };
    const replaceArrayItem = (key, id, newItem) => {
        dispatch({ type: 'REPLACE_ARRAY_ITEM', payload: { key, id, newItem } });
    };
    const removeEventById = (eventId) => ({
        type: 'REMOVE_EVENT_BY_ID',
        payload: eventId
    });
    const updateAttendeeStatus = (event) => ({
        type: 'UPDATE_ATTENDEE_STATUS',
        payload: event
    });

    const resetState = () => {
        setErrors({});
        setSelectedDays([]);
        dispatch({ type: 'RESET_STATE' });
    };
    const getLeagues = async (club_id, page = 1) => {
        setIsLoading(true);

        await axios
            .get(
                `/api/leagues`,
                { params: { club_id, page } },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((res) => {
                setIsLoading(false);
                if (club_id) {
                    setClubLeagues(res.data.leagues);
                } else {
                    setLeagues(res.data.data.leagues);
                }
            })
            .catch((err) => {
                console.log('ERR2', err);
                setIsLoading(false);
            });
    };
    const [league, setLeague] = useState({});
    const getLeague = async (id) => {
        setIsLoading(true);

        await axios
            .get(`/api/league/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                console.log('res', res.data);
                setIsLoading(false);
                setLeague(res.data.league);
                return res.data.league;
            })
            .catch((err) => {
                console.log('ERR2', err);
                setIsLoading(false);
            });
    };

    const [isUploading, setIsUploading] = useState(false);
    // eslint-disable-next-line default-param-last
    const createLeague = async (id = null, params) => {
        setIsUploading(true);

        const startTime = moment.tz(params.start_time, params.timezone);
        const endTime = moment.tz(params.end_time, params.timezone);
        const originalStartTime = moment(params.start_time);
        const originalEndTime = moment(params.end_time);

        // Convert start_time and end_time to UTC

        // Check if the day has changed when converted to UTC
        if (originalStartTime.date() !== startTime.date()) {
            // Increment the start_date by one day
            params.start_date = moment(params.start_date).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
        }

        if (originalEndTime.date() !== endTime.date()) {
            // Increment the end_date by one day
            params.end_date = moment(params.end_date).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
        }

        // Replace start_time and end_time in params with their UTC formatted strings
        const latitude = params?.location?.geometry?.location?.lat();
        const longitude = params?.location?.geometry?.location?.lng();
        let updatedParams = { ...params };

        if (params.location?.formatted_address) {
            console.log('location', params.location?.address_components);
            updatedParams = {
                ...params,
                start_time: startTime.format('YYYY-MM-DDTHH:mm:ss'),
                end_time: endTime.format('YYYY-MM-DDTHH:mm:ss'),
                street_address: params.location ? params.location?.formatted_address : null,
                city: params.location ? params.location?.address_components[1].long_name : null,
                country: params.location ? params.location?.address_components[4].long_name : null,
                zip: params.location ? params.location?.address_components[5].long_name : null,
                state: params.location ? params.location?.address_components[3].long_name : null,
                venue: params.location ? params.location?.name : null,
                latitude,
                longitude
            };
        }
        // const updatedParams = {
        //     ...params,
        //     start_time: startTime.format('YYYY-MM-DDTHH:mm:ss'),
        //     end_time: endTime.format('YYYY-MM-DDTHH:mm:ss'),
        //     // venue,
        //     street_address: params.location?.formatted_address,
        //     city: params.location?.address_componenets[2].long_name,
        //     country: params.location?.address_componenets[5].long_name,
        //     zip: params.location?.address_componenets[6].long_name,
        //     state: params.location?.address_componenets[4].long_name,
        //     latitude,
        //     longitude
        // };

        const response = await axios
            .post(`/api/league`, updatedParams, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsUploading(false);
                return res.data.data;
            })
            .catch((err) => {
                console.log('ERR4', err);
                setIsUploading(false);
            });

        return response;
    };
    const generateMatchups = async (params) => {
        setIsUploading(true);

        await axios
            .post(`/api/matchups/generate`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsUploading(false);
                setErrors({});
            })
            .catch((err) => {
                setErrors({ generate: 'Not groups of 4' });
                setIsUploading(false);
                throw err;
            });
    };
    const addGameScores = async (params) => {
        const response = await axios
            .post(`/api/game/scores`, params)
            .then((res) => {
                setIsUploading(false);
                setErrors({});
                return res.data.score;
            })
            .catch((err) => {
                console.log(err);
                setIsUploading(false);
                throw err;
            });

        setEventMatchups((prevState) =>
            prevState.map((match) => {
                if (match.id === response.id) {
                    return {
                        ...match,
                        match_games: response.match_games,
                        status: 'completed'
                    };
                }
                return match;
            })
        );
        setEventPools((prevState) =>
            prevState.map((pool) => {
                if (pool.id === response.playing_group_id) {
                    return {
                        ...pool,
                        pool_teams: response?.pool?.pool_teams,
                        status: response?.pool?.status,
                        rounds: response?.pool?.rounds
                    };
                }
                return pool;
            })
        );
    };
    const addGameScore = async (params) => {
        setIsUploading(true);

        try {
            const response = await axios.post(`/api/game/score`, params);

            const newScoreData = response.data.score;

            console.log('newScoreData', newScoreData);

            setEventMatchups((prevState) =>
                prevState.map((match) => {
                    if (match.id === newScoreData.id) {
                        const updatedMatchGames = match.match_games.map((game) => {
                            if (game.id === newScoreData.game_id) {
                                const updatedGameScores = game.game_scores.map((gameScore) => {
                                    if (gameScore.id === newScoreData.id) {
                                        return newScoreData; // Replace with new data
                                    }
                                    return gameScore; // Keep existing data
                                });

                                // Check if the game_score was replaced or needs to be appended
                                if (!updatedGameScores.some((score) => score.id === newScoreData.id)) {
                                    updatedGameScores.push(newScoreData); // Append if not replaced
                                }

                                return {
                                    ...game,
                                    game_scores: updatedGameScores
                                };
                            }
                            return game;
                        });

                        return {
                            ...match,
                            match_games: updatedMatchGames,
                            status: 'completed' // Update this as per your requirement
                        };
                    }
                    return match;
                })
            );

            setEventPools((prevState) =>
                prevState.map((pool) => {
                    if (pool.id === newScoreData.game.match.playing_group_id) {
                        // Map rounds and update them
                        let updatedRounds = null;
                        if (pool.rounds) {
                            updatedRounds = pool?.rounds?.map((round) => {
                                if (round.id === newScoreData.game.match.round_id) {
                                    // Map matches and update the specific match
                                    const updatedMatches = round.matches.map((match) => {
                                        if (match.id === newScoreData.game.match_id) {
                                            return { ...match, status: 'completed' }; // Update the match status
                                        }
                                        return match; // Return other matches as is
                                    });

                                    return { ...round, matches: updatedMatches }; // Return the updated round
                                }
                                return round; // Return other rounds as is
                            });
                        }
                        const updatedPoolTeams = pool?.pool_teams?.map((team) => {
                            if (team.id === newScoreData.team?.playing_group?.id) {
                                return {
                                    ...team,
                                    won: newScoreData.team.playing_group.won,
                                    lost: newScoreData.team.playing_group.lost,
                                    points_earned: newScoreData.team.playing_group.points_earned,
                                    points_against: newScoreData.team.playing_group.points_against,
                                    point_differntial: newScoreData.team.playing_group.point_differntial
                                };
                            }
                            return team;
                        });

                        return {
                            ...pool,
                            rounds: updatedRounds,
                            status: newScoreData.game.match.round.playing_group.status,
                            pool_teams: updatedPoolTeams
                        }; // Return the updated pool
                    }
                    return pool; // Return other pools as is
                })
            );

            setIsUploading(false);
            return newScoreData;
        } catch (err) {
            console.log('ERR5', err);
            setIsUploading(false);
        }
    };
    const updateGameScore = async (id, params) => {
        setIsUploading(true);
        console.log('updating');

        try {
            const response = await axios.put(`/api/game/score/${id}`, params);
            const newScoreData = response.data.score;

            setEventMatchups((prevState) =>
                prevState.map((match) => {
                    if (match.id === newScoreData.game.match_id) {
                        const updatedMatchGames = match.match_games.map((game) => {
                            if (game.id === newScoreData.game_id) {
                                const updatedGameScores = game.game_scores.map((gameScore) => {
                                    if (gameScore.id === newScoreData.id) {
                                        return newScoreData; // Replace with new data
                                    }
                                    return gameScore; // Keep existing data
                                });

                                // Check if the game_score was replaced or needs to be appended
                                if (!updatedGameScores.some((score) => score.id === newScoreData.id)) {
                                    updatedGameScores.push(newScoreData); // Append if not replaced
                                }

                                return {
                                    ...game,
                                    game_scores: updatedGameScores
                                };
                            }
                            return game;
                        });

                        return {
                            ...match,
                            match_games: updatedMatchGames,
                            status: 'completed' // Update this as per your requirement
                        };
                    }
                    return match;
                })
            );

            setIsUploading(false);
            return newScoreData;
        } catch (err) {
            console.log('ERR1', err);
            setIsUploading(false);
        }
    };

    const getMatch = async (id, event_id, type) => {
        setIsLoading(true);

        const response = await axios
            .get(`/api/match/${id}`, { params: { event_id, type } })
            .then((res) => {
                setIsLoading(false);
                return res.data.match;
            })
            .catch((err) => {
                setIsLoading(false);
                throw err;
            });
        return response;
    };

    const getEventAssignments = async (id) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/event/assignment/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                return res.data.assignments;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getLeagueEventList = async (id, type) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/${type}/events/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                const filteredList = res.data.data.filter((item) => item.status !== 'cancelled');
                setValue('leagueEventList', filteredList);
                return filteredList;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getEventMatchups = async (id) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/event/matchups/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                return res.data.matchups;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const [leagueTrends, setLeagueTrends] = useState([]);
    const getLeagueTrends = async (id) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/league/trends/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                setLeagueTrends(res.data);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
                console.log('err', err);
                throw err;
            });
        return response;
    };
    const [leagueLeaderboard, setLeagueLeaderboard] = useState([]);
    const getLeagueSeedLeaderboard = async (id) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/league/leaderboard/${id}/seed`)
            .then((res) => {
                setIsLoading(false);
                return res.data.leaderboard;
            })
            .catch((err) => {
                setIsLoading(false);
                console.log('err', err);
                throw err;
            });
        return response;
    };
    const getLeagueLeaderboard = async (id, event_instance_id, week = null) => {
        const params = week ? { event_instance_id, week } : { event_instance_id };
        setIsLoading(true);
        const response = await axios
            .get(
                `/api/league/leaderboard/${id}`,
                { params },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((res) => {
                setIsLoading(false);
                setLeagueLeaderboard(res.data.leaderboard);
                setValue('hasLeaderboard', res.data.has_leaderboard);
                setValue('week', res.data.week);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
                console.log('err', err);
                throw err;
            });
        return response;
    };
    const reorderStandings = async (leagueId, sourceRank, destinationRank, week) => {
        setIsLoading(true);
        const path = `/api/league/${leagueId}/standings`;
        const response = await axios
            .post(path, { sourceRank, destinationRank, week })
            .then((res) => {
                setIsLoading(false);
                if (res?.data?.leaderboard) {
                    return res.data.leaderboard;
                }

                return [];
            })
            .catch((err) => {
                setIsLoading(false);
                console.log('err', err);
                throw err;
            });
        return response;
    };
    const getLeaguePlayers = async (leagueId, event_instance_id = null, week_number = null) => {
        setIsLoading(true);
        const params = {};
        if (event_instance_id) {
            params.event_instance_id = event_instance_id;
        }
        if (week_number) {
            params.week_number = week_number;
        }
        const response = await axios
            .get(`/api/league/players/${leagueId}`, { params })
            .then((res) => {
                setIsLoading(false);
                setLeagueLeaderboard(res.data.leaderboard);
                console.log('leaderboard', res.data.leaderboard);
                setValue('hasLeaderboard', true);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
                console.log('err', err);
                throw err;
            });
        return response;
    };
    const checkLeagueMatchups = async (event_instance_id) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/league/matches/check`, { params: { event_instance_id } })
            .then((res) => {
                setIsLoading(false);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const [leagueStats, setLeagueStats] = useState([]);
    const getLeagueStats = async (id) => {
        setIsLoading(true);
        const response = await axios
            .get(`/api/league/stats/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setIsLoading(false);
                setLeagueStats(res.data.leaderboard);

                return res.data.leaderboard;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getLeagueRoster = async (id) => {
        setIsLoading(true);
        const response = await axios
            .get(
                `/api/league/roster/${id}`,

                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((res) => {
                setIsLoading(false);
                setLeagueLeaderboard(res.data.leaderboard);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };

    const getEventWaitlist = async (id, type) => {
        setIsLoading(true);
        const response = await axios
            .get(
                `/api/event/waitlist/${id}`,
                { params: { type } },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((res) => {
                setIsLoading(false);
                console.log('waitlist res', res.data);
                setValue('eventWaitlist', res.data.data);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const upgradeFromWaitlist = async (id) => {
        setIsLoading(true);
        const response = await axios
            .post(
                `/api/event/waitlist/upgrade/${id}`,

                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((res) => {
                setIsLoading(false);
                const response = res.data.data;
                replaceArrayItem('eventWaitlist', response.id, response);
                // setValue('eventWaitlist', res.data.data);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const upgradeTeamFromWaitlist = async (team_id, league_id) => {
        setIsLoading(true);
        const params = {
            team_id,
            league_id
        };
        console.log('params', params);

        const response = await axios
            .post(`/api/event/team/upgrade`, params)
            .then((res) => {
                setIsLoading(false);
                // const response = res.data.data;
                // replaceArrayItem('eventWaitlist', response.id, response);
                // setValue('eventWaitlist', res.data.data);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
                throw err;
            });
        return response;
    };
    const joinEventWaitlist = async (user_id, product_id = null, type = null, checkout = null) => {
        const params = {
            user_id,
            type,
            product_id,
            checkout
        };
        const response = await axios
            .post(`/api/event/waitlist`, params)
            .then((res) => {
                setIsLoading(false);
                const response = res.data.data;
                setArray('eventWaitlist', {
                    name: response.user.name,
                    profile_picture_url: response.user.user_attributes.profile_picture_url,
                    rating: response.user.user_attributes.rating
                });
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };

    const getEventList = async (upcoming, club_id) => {
        const response = await axios
            .get(`/api/tournaments/admin`, { params: { upcoming, club_id } })
            .then((res) => {
                setValue('loading', { ...state.loading, eventList: false });
                setValue('eventList', res.data.events);
                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data;
            })
            .catch((err) => {
                setValue('loading', { ...state.loading, eventList: false });
                console.log('err', err);
            });
        return response;
    };
    const getLeagueAttendeeList = async (event_instance_id, league_id) => {
        updateValue('loading.leagueRoster', true);
        const response = await axios
            .get(`/api/league/event/attendees`, { params: { event_instance_id, league_id } })
            .then((res) => {
                setIsLoading(false);
                setValue('attendeeList', res.data.data);
                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.events;
            })
            .catch((err) => {
                setIsLoading(false);
            })
            .finally(() => {
                updateValue('loading.leagueRoster', false);
            });
        return response;
    };
    const uploadLeagueRoster = async (roster, league_id) => {
        const formData = new FormData();

        formData.append('roster', roster);
        formData.append('league_id', league_id);

        setValue('isLoading', true);
        const response = await axios
            .post(`/api/league/roster/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // This should be 'multipart/form-data' for FormData
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                getLeagueAttendeeList(null, league_id);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
                throw err;
            });
        return response;
    };

    const replaceLeaguePlayer = async (replaced_user_id, new_user_id, league_id, is_sub, event_instance_id, playing_group_id) => {
        const params = {
            replaced_user_id,
            new_user_id,
            league_id,
            is_sub,
            event_instance_id,
            playing_group_id
        };
        const response = await axios
            .post(`/api/league/user/replace`, params)
            .then((res) => {
                setIsLoading(false);
                const newUserData = res.data.data;
                let updatedAttendeeList = null;
                if (is_sub) {
                    updatedAttendeeList = [...state.attendeeList, newUserData];
                } else {
                    const filteredAttendees = state.attendeeList.filter((usr) => usr.id !== replaced_user_id);
                    updatedAttendeeList = [...filteredAttendees, newUserData];
                }

                setValue('attendeeList', updatedAttendeeList);

                // setLeagueLeaderboard(res.data.leaderboard);
                return newUserData;
            })
            .catch((err) => {
                setIsLoading(false);
                throw err;
            });
        return response;
    };

    // only supports is_sub and absent right now
    const putUserPlayingGroup = async (id, params) => {
        setIsLoading(true);

        const response = await axios
            .put(`/api/user-playing-groups/${id}`, params)
            .then((res) => {
                setIsLoading(false);

                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
                return err;
            });
        return response;
    };
    const deleteUserFromPlayingGroup = async (id, params) => {
        const response = await axios
            .delete(`/api/user-playing-groups/${id}`, params)
            .then((res) => res.data)
            .catch((err) => err);
        return response;
    };
    const getPlayingGroupPoll = async (playing_group_id) => {
        dispatch({ type: 'SET_LOADING_STATE', payload: { key: 'playingGroupPoll' } });

        const response = await axios
            .get(`/api/playing-group/poll/${playing_group_id}`)
            .then((res) => {
                setIsLoading(false);

                setValue('playingGroupPoll', res.data.data);
                dispatch({ type: 'CLEAR_LOADING_STATE', payload: { key: 'playingGroupPoll' } });

                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.events;
            })
            .catch((err) => {
                dispatch({ type: 'CLEAR_LOADING_STATE', payload: { key: 'playingGroupPoll' } });
            });
        return response;
    };
    const getTournamentAttendeeList = async (event_instance_id) => {
        const response = await axios
            .get(`/api/league/player-list`, { params: { event_instance_id } })
            .then((res) => {
                setIsLoading(false);
                console.log('res', res.data.data);
                setValue('attendeeList', res.data.data);
                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.events;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const postPlayersToLeaderboard = async (userList, week, leagueId, eventInstanceId) => {
        const response = await axios
            .put(`/api/league/leaderboard/${leagueId}`, { userList, week, eventInstanceId })
            .then((res) => {
                setLeagueLeaderboard(res.data.leaderboard);

                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err;
            });
        return response;
    };
    const deleteTournament = async (id) => {
        const response = await axios
            .delete(`/api/tournament/${id}`)
            .then((res) => {
                setIsLoading(false);
                console.log('res', res.data);
                console.log('id', id);
                state.eventList.map((item) => {
                    console.log('item', item);
                });
                const newState = state.eventList.map((item) =>
                    item.event.id === id ? { ...item, event: { ...item.event, status: 'cancelled' } } : item
                );
                setValue('eventList', newState);
                // dispatch(removeEventById(id));

                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setIsLoading(false);
                throw err;
            });
        return response;
    };
    const deleteLeague = async (id) => {
        const response = await axios
            .delete(`/api/league/${id}`)
            .then((res) => {
                setIsLoading(false);

                const newState = state.eventList.map((item) =>
                    item.event.id === id ? { ...item, event: { ...item.event, status: 'cancelled' } } : item
                );
                setValue('eventList', newState);
                // dispatch(removeEventById(id));

                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setIsLoading(false);
                throw err;
            });
        return response;
    };

    const getEventPools = async (event_instance_id) => {
        const response = await axios
            .get(`/api/tournament/pools`, {
                params: {
                    event_instance_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getLeagueGroups = async (event_id) => {
        const response = await axios
            .get(`/api/league/groups/${event_id}`, {
                params: {
                    event_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setIsLoading(false);
            });
        return response;
    };
    const getPoolRounds = async (playing_group_id) => {
        const response = await axios
            .get(`/api/tournament/pools/rounds`, {
                params: {
                    playing_group_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getBracketRounds = async (bracket_id) => {
        const response = await axios
            .get(`/api/tournament/bracket/rounds`, {
                params: {
                    bracket_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getUserMatches = async (user_id, type = 'upcoming') => {
        const response = await axios
            .get(`/api/user/matches`, {
                params: {
                    user_id,
                    type
                }
            })
            .then((res) => {
                setIsLoading(false);
                setValue('userMatches', res.data.data);
                return res.data.data;
            })
            .catch((err) => {
                console.log('getUserMatches error', err);
                setIsLoading(false);
            });
        return response;
    };
    const getPoolRoundMatches = async (round_id) => {
        const response = await axios
            .get(`/api/tournament/pools/matchups`, {
                params: {
                    round_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setIsLoading(false);
            });
        return response;
    };
    const getLeagueGroupMatches = async (playing_group_id) => {
        const response = await axios
            .get(`/api/league/pools/matchups`, {
                params: {
                    playing_group_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getBracketRoundMatches = async (bracket_id, round_id) => {
        console.log('rid', round_id);
        const response = await axios
            .get(`/api/tournament/bracket/matchups`, {
                params: {
                    bracket_id,
                    round_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getEventTeams = async (event_instance_id) => {
        updateValue('loading.eventTeams', true);
        const response = await axios
            .get(`/api/tournament/event/teams`, {
                params: {
                    event_instance_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                setValue('eventTeams', res.data.data.data);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            })
            .finally(() => {
                updateValue('loading.eventTeams', false);
            });
        return response;
    };
    const getTournamentTeams = async (league_id) => {
        const response = await axios
            .get(`/api/tournament/teams`, {
                params: {
                    league_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                setValue('tournamentTeams', res.data.data.data);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const getEventPlayers = async (event_instance_id) => {
        const response = await axios
            .get(`/api/tournament/event/players`, {
                params: {
                    event_instance_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                console.log(res);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const generatePools = async (
        event_instance_id,
        number_of_pools,
        play_until,
        win_by_two,
        scoring_type,
        league_id,
        available_courts,
        best_of,
        start_tournament,
        double_round_robin
    ) => {
        const params = {
            event_instance_id,
            number_of_pools,
            play_until,
            win_by_two,
            league_id,
            scoring_type,
            available_courts,
            best_of,
            start_tournament,
            double_round_robin
        };
        const response = await axios
            .post(`/api/tournament/pools/generate`, params)
            .then((res) => {
                setIsLoading(false);
                console.log(res);
                if (start_tournament) {
                    setValue('selectedEvent', { ...state.selectedEvent, status: 'in_progress' });
                    const option = state.options.find((item) => item.id === event_instance_id);
                    const updatedOption = {
                        ...option,
                        status: 'in_progress' // or any other property you need to update
                    };
                    const newState = state.options.filter((item) => item.id !== event_instance_id);
                    setValue('options', [...newState, updatedOption]);
                }
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const [pools, setPools] = useState();
    const [teams, setTeams] = useState();
    const [matches, setMatches] = useState([]);

    const getEventBracket = async (event_instance_id) => {
        const response = await axios
            .get(`/api/tournament/bracket`, {
                params: {
                    event_instance_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                if (!res.data.data) {
                    setPools(res.data.pools);
                    setTeams(res.data.teams);
                    setMatches([]);
                } else if (res.data.type === 'single') {
                    setMatches(res.data.data.matches);
                } else if (res.data.type === 'double') {
                    setMatches({ upper: res.data.data.upper.matches, lower: res.data.data.lower.matches });
                }
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data;
            })
            .catch((err) => {
                setIsLoading(false);
            });
        return response;
    };
    const generateBracket = async (
        event_instance_id,
        num_of_rounds,
        best_of,
        win_by_two,
        play_until,
        league_id,
        num_of_teams,
        scoring_type,
        start_tournament
    ) => {
        const params = {
            event_instance_id,
            num_of_rounds,
            best_of,
            win_by_two,
            play_until,
            league_id,
            num_of_teams,
            scoring_type,
            start_tournament
        };
        const response = await axios
            .post(`/api/tournament/bracket/generate`, params)
            .then((res) => {
                setIsLoading(false);
                getEventBracket(event_instance_id);

                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
                throw err;
            });
        return response;
    };
    const generateLeaderboard = async (event_instance_id, league_id) => {
        const params = {
            event_instance_id,
            league_id
        };
        const response = await axios
            .post(`/api/league/standings/generate`, params)
            .then((res) => {
                setIsLoading(false);

                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
                throw err;
            });
        return response;
    };

    const updateEventInvitationStatus = async (id, status, all_instances) => {
        setIsLoading(true);
        const data = { id, status };
        const response = await axios
            .put(`/api/event/invitation/${id}?status=${status}&all_instances=${all_instances}`, null, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                console.log('res22', res);
                dispatch(updateAttendeeStatus(res.data.data));
                setIsLoading(false);

                return res.data;
            })
            .catch((error) => {
                setIsLoading(false);
                // console.log(error.message);

                // console.log(error);
                throw error;
            });

        return response;
    };
    const updateEventInvitationStatusBulk = async (invitation_ids, status) => {
        console.log('invitaion_ids', invitation_ids);
        setIsLoading(true);
        const response = await axios
            .put(`/api/event/invitation/bulk`, { invitation_ids, status })
            .then((res) => {
                console.log('res32', res);
                res.data.data.map((item) => {
                    dispatch(updateAttendeeStatus(item));
                });
                setIsLoading(false);

                return res.data;
            })
            .catch((error) => {
                console.log('error', error);
                setIsLoading(false);
                // console.log(error.message);

                // console.log(error);
                throw error;
            });

        return response;
    };

    const uploadLeagueToDupr = async (event_instance_id) => {
        setValue('isUploadingToDupr', true);
        const params = {
            event_instance_id
        };
        const response = await axios
            .post(`/api/league/matches/upload/dupr`, params)
            .then((res) => {
                setIsLoading(false);
                console.log('res', res);
                actionSnackbar(true, 'Matches uploaded to DUPR');
                // setLeagueLeaderboard(res.data.leaderboard);
                setValue('isUploadingToDupr', false);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err);
                setIsLoading(false);
                setValue('isUploadingToDupr', false);
                // const message = err.response.data.data.map((error) => error.message).join('\n');
                // throw message;
                throw 'fail';
            });
        return response;
    };
    const uploadMatchesToDupr = async (match_id) => {
        console.log('match_id', match_id);
        const params = {
            match_id
        };
        const response = await axios
            .post(`/api/matches/upload/dupr`, params)
            .then((res) => {
                setIsLoading(false);
                console.log('res', res);
                actionSnackbar(true, 'Matches uploaded to DUPR');
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                console.log('err', err.response.data);
                setIsLoading(false);
                // const message = err.response.data.data.map((error) => error.message).join('\n');
                // throw message;
            });
        return response;
    };

    const getEventTypes = async (type) => {
        const response = await axios
            .get(`/api/event/types`, {
                params: {
                    type
                }
            })
            .then((res) => {
                setIsLoading(false);
                const newValue = res.data.map((eventType) => ({ value: eventType.id, label: eventType.description }));
                setValue('eventTypes', newValue);
                return newValue; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const getEventChecklist = async (event_id, type) => {
        const response = await axios
            .get(`/api/event/checklist`, {
                params: {
                    event_id,
                    type
                }
            })
            .then((res) => {
                setIsLoading(false);
                setValue('eventChecklist', res.data.data);
                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const deleteTeam = async (team_id) => {
        const response = await axios
            .delete(`/api/event/team/${team_id}`)
            .then((res) => {
                setIsLoading(false);
                console.log('existing state', state.eventTeams, team_id);
                const newState = state.eventTeams.filter((item) => item.id !== team_id);
                console.log('newState', newState);
                setValue('eventTeams', newState);
                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const createNewTeam = async (params) => {
        const response = await axios
            .post(`/api/event/team`, params)
            .then((res) => {
                setIsLoading(false);

                const newState = [...state.eventTeams, res.data.data];
                setValue('eventTeams', newState);
                // setLeagueLeaderboard(res.data.leaderboard);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
                setErrors({ newTeam: err.response.data.message });

                throw err;
            });
        return response;
    };
    const duplicateLeague = async (league_id, start_date) => {
        const response = await axios
            .post(`/api/league/duplicate`, { league_id, start_date })
            .then((res) => {
                setIsLoading(false);
                const newState = [...state.eventList, res.data.data];
                setValue('eventList', newState);
                return res.data.data;
            })
            .catch((err) => {
                setIsLoading(false);
                setErrors({ newTeam: err.response.data.message });

                throw err;
            });
        return response;
    };
    const editDivisionStatus = async (event_instance_id, league_id, status) => {
        const response = await axios
            .post(`/api/division/status`, { event_instance_id, league_id, status })
            .then((res) => {
                setValue('selectedEvent', { ...state.selectedEvent, status });
                const option = state.options.find((item) => item.id === event_instance_id);
                const updatedOption = {
                    ...option,
                    status // or any other property you need to update
                };
                const newState = state.options.filter((item) => item.id !== event_instance_id);
                setValue('options', [...newState, updatedOption]);
            })
            .catch((err) => {
                throw err;
            });
        return response;
    };
    const getUserPools = async (user_id, event_id) => {
        const response = await axios
            .get(`/api/user/pools`, {
                params: {
                    event_id,
                    user_id
                }
            })
            .then((res) => {
                setIsLoading(false);
                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const deleteLeagueLeaderboard = async (week_id) => {
        const response = await axios
            .delete(`/api/league/leaderboard/${week_id}`)
            .then((res) =>
                // setValue('leagueCourts', res.data.data);
                setLeagueLeaderboard([])
            )
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const [selectedPool, setSelectedPool] = useState(0);
    const getTypePools = (type, selectedEvent) => {
        if (type === 'league') {
            getLeagueGroups(selectedEvent?.id).then((res) => {
                if (res?.length > 0) {
                    setEventPools(res);
                    setSelectedPool(res[0]);
                    setValue('poolType', res[0].name);
                } else {
                    setEventPools([]);
                    setSelectedPool('');
                    setValue('poolType', undefined);
                }
            });
        } else if (type === 'tournament') {
            getEventPools(selectedEvent?.id).then((res) => {
                setValue('eventBracket', res.bracket);
                if (res.data?.length > 0) {
                    setEventPools(res.data);

                    setSelectedPool(res.data[0]);
                } else {
                    setEventPools([]);
                    setSelectedPool('');
                    setValue('poolType', undefined);
                }
                if (res.bracket) {
                    setSelectedPool(res.bracket);
                    setValue('poolType', 'Bracket');
                }
            });
        }
    };
    const searchDivisionTeams = async (division_id, search, team) => {
        const response = await axios
            .get(`/api/division/teams`, {
                params: {
                    division_id,
                    search
                }
            })
            .then((res) => {
                console.log('res', res.data.teams);
                updateValue(`divisionTeamResults.${team}`, res.data.teams); // Correct key path for nested update
                return res.data.teams; // Return the teams array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const updateMatchTeams = async (match_id, team_a, team_b) => {
        const params = {
            team_a_id: team_a?.id,
            team_b_id: team_b?.id
        };
        const response = await axios
            .put(`/api/match/teams/${match_id}`, params)
            .then((res) => {
                console.log('res', res.data.teams);
                setValue('divisionTeamResults', res.data.teams);
                setEventMatchups((prevState) =>
                    prevState.map((match) => {
                        if (match.id === match_id) {
                            return {
                                ...match,
                                team_a,
                                team_b,
                                team_a_id: team_a?.id,
                                team_b_id: team_b?.id
                            };
                        }
                        return match;
                    })
                );
                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const deleteFromLeagueLeaderboard = async (standingId) => {
        const response = await axios
            .delete(`/api/league/leaderboard/standing/${standingId}`)
            .then((res) => res.data.leaderboard)
            .catch((err) => {
                console.error(err);
            });
        return response;
    };
    const deleteMatch = async (matchId) => {
        const response = await axios
            .delete(`/api/match/${matchId}`)
            .then((res) => {
                setEventMatchups((prevEventMatchups) => prevEventMatchups.filter((matchup) => matchup.id !== res.data.match?.id));
            })
            .catch((err) => {
                console.error(err);
            });
        return response;
    };

    return (
        <LeaguesContext.Provider
            value={{
                ...state,
                setValue,
                updateValue,
                createLeague,
                isUploading,
                leagues,
                getLeagues,
                isLoading,
                getEventAssignments,
                getEventMatchups,
                addGameScore,
                eventMatchups,
                setEventMatchups,
                updateGameScore,
                getMatch,
                getLeagueLeaderboard,
                getLeagueSeedLeaderboard,
                leagueLeaderboard,
                getLeagueTrends,
                leagueTrends,
                resetState,
                errors,
                setErrors,
                selectedDays,
                setSelectedDays,
                generateMatchups,
                getEventList,
                getEventPools,
                getPoolRounds,
                getPoolRoundMatches,
                getEventTeams,
                generatePools,
                eventPools,
                setEventPools,
                getEventBracket,
                pools,
                setPools,
                teams,
                setTeams,
                generateBracket,
                matches,
                getBracketRounds,
                getBracketRoundMatches,
                clubLeagues,
                getLeague,
                league,
                setLeague,
                getLeagueGroups,
                getLeagueRoster,
                getLeagueStats,
                leagueStats,
                getLeagueEventList,
                getLeagueGroupMatches,
                deleteTournament,
                deleteLeague,
                getLeagueAttendeeList,
                updateEventInvitationStatus,
                updateEventInvitationStatusBulk,
                getTournamentAttendeeList,
                getEventPlayers,
                addGameScores,
                checkLeagueMatchups,
                generateLeaderboard,
                getLeaguePlayers,
                reorderStandings,
                eventModalOpen,
                handleToggleModal,
                getEventWaitlist,
                joinEventWaitlist,
                upgradeFromWaitlist,
                getPlayingGroupPoll,
                putUserPlayingGroup,
                deleteUserFromPlayingGroup,
                replaceLeaguePlayer,
                uploadLeagueToDupr,
                getEventTypes,
                getEventChecklist,
                deleteTeam,
                createNewTeam,
                duplicateLeague,
                getUserPools,
                upgradeTeamFromWaitlist,
                getTournamentTeams,
                setMatches,
                replaceArrayItem,
                deleteLeagueLeaderboard,
                getUserMatches,
                getTypePools,
                selectedPool,
                setSelectedPool,
                setLeagueLeaderboard,
                editDivisionStatus,
                postPlayersToLeaderboard,
                searchDivisionTeams,
                updateMatchTeams,
                deleteFromLeagueLeaderboard,
                uploadLeagueRoster,
                uploadMatchesToDupr,
                deleteMatch
            }}
        >
            {children}
        </LeaguesContext.Provider>
    );
}
