/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';

// assets
import DeleteIcon from '@mui/icons-material/Delete';

const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 2,
            pr: 1,
            color: numSelected > 0 ? 'secondary.main' : 'inherit'
        }}
    >
        {numSelected > 0 ? (
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="h4" component="div">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                Nutrition
            </Typography>
        )}

        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

export default EnhancedTableToolbar;
